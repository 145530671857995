.contenedorFormEscribenos {
    margin-bottom: 190px;
    form {
        background: #FFFFFF;
        box-shadow: 0px 10px 30px RGB(0 0 0 / 5%);
        border-radius: 10px;
        padding: 20px;
        width: 684px;
        margin: 0 auto;
        @media (max-width: 768px) {
            width: 100%;
        }
        .contenedorFormEscribenos-inputs {
            display: flex;
            gap: 20px;
            justify-content: center;
            @media (max-width: 475px) {
                flex-direction: column;
            }
            div {
                display: flex;
                flex-direction: column;
                width: 50%;
                @media (max-width: 475px) {
                    width: 100%;
                }
                label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: $zurich-black;
                    margin-bottom: 10px;
                }
                input {
                    border: 1px solid $zurich-dark-grey;
                    border-radius: 5px;
                    margin-bottom: 10px;
                }
            }
        }
        .contenedorFormEscribenos-textarea {
            display: flex;
            div {
                width: 100%;
                label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: $zurich-black;
                    margin-bottom: 10px;
                }
                textarea {
                    border: 1px solid $zurich-dark-grey;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    resize: none;
                }
            }
        }
        .contenedorFormEscribenos-submit {
            margin-top: 10px;
            button {
                background: $zurich-blue;
                border-radius: 50px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.03em;
                color: $zurich-white;
                border: none;
                padding: 10px 20px;
                &:hover {
                    background: $zurich-dark-blue;
                }
                img {
                    margin-left: 35px;
                }
            }
        }
    }
}