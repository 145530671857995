.contenedorItemsPreguntasFrecuentes {
    margin-bottom: 90px;
    .contenedorItemsPreguntasFrecuentes-title {
        display: grid;
        grid-template-columns: 25% auto 25%;
        margin-bottom: 50px;
        a {
            text-decoration: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $zurich-dark-grey;
            display: flex;
            align-items: center;
            img {
                margin-right: 11px;
            }
        }
        h1 {
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            color: $zurich-dark-blue;
            text-align: center;
        }
    }
    .contenedorItemsPreguntasFrecuentes-body {
        width: 447px;
        margin: 0 auto;
        @media (max-width: 475px) {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }
        .contenedorItemsPreguntasFrecuentes-body-items {
            h3 {
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: $zurich-red;
                margin-bottom: 15px;
            }
            .accordion {
                .accordion-item {
                    border: none;
                    margin-bottom: 15px;
                    .accordion-header {
                        button {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 22px;
                            color: $zurich-dark-blue;
                        }
                    }
                    .accordion-collapse {
                        .accordion-body {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            color: #000000;
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }
}
.accordion-button:not(.collapsed) {
    background-color: transparent !important;
    box-shadow: none !important;
}
.accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color:transparent;
}
.accordion-button:not(.collapsed)::after {
    background-image: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ec0000%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>') !important;
}
