.contenedorContenidoSiglePost {
    margin-bottom: 120px;
    img {
        margin-bottom: 40px;
    }
    .cotenedorDescriptionSinglePost {
        padding: 0 160px;
        @media (max-width: 768px) {
            padding: 0 25px;
        }
        .contenedorContenidoSiglePost-extract {
            font-weight: 700;
            font-size: 18px;
            line-height: 38px;
            color: $zurich-dark-blue;
            margin-bottom: 30px;
        }
        .contenedorContenidoSiglePost-description {
            font-weight: 400;
            font-size: 16px;
            line-height: 38px;
            color: $zurich-dark-blue;
        }
    }
    
}