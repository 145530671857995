.contenedorHaceTuDenuncia {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 30px;
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .contenedorHaceTuDenuncia-title {
        display: flex;
        .contenedorHaceTuDenuncia-title-icon {
            margin-right: 15px;
        }
        .contenedorHaceTuDenuncia-title-text {
            h6 {
                font-weight: 700;
                font-size: 24px;
                line-height: 35px;
                color: $zurich-red;
            }
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $zurich-dark-blue;
            }
        }
    }
    .contenedorHaceTuDenuncia-btn {
        a {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: $zurich-white;
            text-decoration: none;
            background: $zurich-red;
            border-radius: 50px;
            padding: 18px 30px;
            @media (max-width: 375px) {
                font-size: 10px;
            }
            &:hover {
                background: $zurich-dark-red;
            }
        }
        
    }
}