.navbar-zurich {
  background: white;
  box-shadow: 0px 10px 30px RGB(0 0 0/5%);
  .nav-link {
    color: $zurich-black !important;
    padding: 0;
    font-size: 14px;
    line-height: 19px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 20px;
    margin-left: 20px;
    @media (max-width: 768px) {
      &:first-child {
        margin-top: 20px;
      }
      font-size: 17px;
      margin-bottom: 20px;
    }
  }
  .icon-search {
    padding: 0 !important;
    margin-right: 45px;
    margin-left: 35px;
  }
  .navbar-btn-ingresar {
    padding: 8px 16px;
    border-radius: 10px;
    background-color: $zurich-blue;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: $zurich-white !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    &:hover {
      background-color: $zurich-dark-blue;
    }

    &.little {
      padding-right: 5px !important;
      padding-left: 5px !important;
      font-size: 11px !important;
      margin: 0 !important ;
    }
  }
}

// Menu desplegable

.menuDesplegadoHeader1 {
  position: absolute;
  background: #ffffff;
  border-radius: 20px;
  padding: 0px 22px 0px 30px;
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.2));
  width: 333px;
  display: flex;
  flex-direction: column;
  height: 168px;
  justify-content: space-evenly;
  top: 39px;
  display: none;
  @media (min-width: 1920px) {
    top: 55px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
  }
  img {
    top: -9px;
    position: absolute;
    left: 20px;
    position: absolute;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  a {
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $zurich-black;
    display: flex;
    flex-direction: column;
    span {
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $zurich-dark-grey;
    }
  }
}
.itemConMenuDesplegable1 {
  position: relative;

  &:hover .menuDesplegadoHeader1 {
    display: flex;
  }
}
#basic-navbar-nav {
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}
.modal-content {
  border: none;
}
.close-personalizado-2 {
  position: absolute;
  top: 55px;
  right: 35px;
  z-index: 1;
}
.correcionHoverMenu {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  @media (min-width: 1920px) {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }
}
