.copy-footer {
  background-color: $zurich-black;
  padding-top: 25px;
  padding-bottom: 25px;
  div.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: $zurich-white;
    margin-bottom: 0;
  }
}
.parrafo-footer {
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    margin-bottom: 40px;
    color: $zurich-dark-grey;
    a {
      color: $zurich-dark-grey;
      text-decoration: none;
      font-weight: 700;
    }
  }
}
.card-datos-footer {
  box-shadow: 10px 10px 30px RGBA(30, 19, 19, 0.21);

  border-radius: 10px;

  padding: 30px;
  margin-bottom: 60px;
  .item-datos-footer {
    border-right: 1px solid #eaeaea;
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 33%;

    img {
      max-width: 300px !important;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      border-right: 0;
      margin-bottom: 15px;
      padding: 0;
      margin-top: 30px;

      img {
        max-width: 200px !important;
      }
    }
  }
  .item-datos-footer:first-child {
    padding-left: 0px;
  }
  .item-datos-footer:last-child {
    padding-right: 0px;
    border-right: none;
  }
  .item-datos-footer h4 {
    color: #7d8082;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .item-datos-footer p {
    color: #7d8082;
    font-weight: 900 !important;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  }
}
.navbar-footer {
  padding: 40px 30px;
  border-radius: 10px;
  background-color: $zurich-blue;
  margin-bottom: 60px;
  .columnas-navbar-footer {
    margin-right: 70px;

    @media (max-width: 767px) {
      margin-right: 0px;
    }

    h5 {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: $zurich-light-blue;
      margin-bottom: 30px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $zurich-white;
          text-decoration: none;
        }
        margin-bottom: 25px;
      }
    }
    .denunciar-siniestro-footer {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      text-decoration: none;
      color: $zurich-red;
      background-color: $zurich-white;
      padding: 18px 30px;
      border-radius: 60px;
      display: inline-block;
      margin-bottom: 30px;
      &:hover {
        color: $zurich-white;
        background-color: $zurich-red;
      }
    }
    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 25px;
      color: $zurich-white;
      margin-bottom: 0;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: $zurich-white;
      margin-bottom: 15px;
    }
    .darse-baja-footer {
      font-weight: 700;
      font-size: 14px;
      line-height: 25px;
      color: $zurich-white;
      margin-bottom: 0;
      text-decoration: none;
    }
  }
}

.portal-agentes {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-decoration: none;
  color: $zurich-blue;
  background-color: #fff;
  padding: 12px;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 10px;
  &:hover {
    color: $zurich-white;
    background-color: $zurich-dark-blue;
  }
}
