.contenedorPortadaTecnologiaProtegida {
    margin-top: 90px;
    margin-bottom: 90px;
    h2 {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $zurich-dark-grey;
    }
    h3 {
        font-weight: 700;
        font-size: 50px;
        line-height: 68px;
        text-align: center;
        color: $zurich-blue;
        
    }
}

