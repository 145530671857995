.modal-header {
    padding: 0;
}
.close-personalizado-1 {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
}
.menuDesplegadoHeader2 {
    position: absolute;
    background: #FFFFFF;
    border-radius: 20px;
    //padding: 0px 22px 0px 30px;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.2));
    width: 757px;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-evenly;
    top: 39px;
    left: -280px;
    display: none;
    @media (min-width: 1920px) {
        top: 55px;
    }
    @media (max-width: 768px) {
        display: flex; 
        position: relative;
        width: 100%;  
        top: 0;
        left: 0;
    }
    .menuDesplegadoHeader2-header {
        display: flex;
        border-bottom: 1px solid $zurich-light-grey;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .menuDesplegadoHeader2-header-items-a {
            padding-top: 40px;
            padding-left: 45px;
            padding-bottom: 50px;
            padding-right: 15px;
            width: 50%;
            @media (max-width: 768px) {
                width: 100%;
            }
            a {
                text-decoration: none;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: $zurich-black;
                display: flex;
                margin-bottom: 43px;
                &:last-child {
                    margin-bottom: 0px;
                }
                img {
                    margin-right: 10px;
                }
            
                span {
                    display: inline-block;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: $zurich-dark-grey;
                }
            }
        }
        .menuDesplegadoHeader2-header-items-b {
            padding-top: 40px;
            padding-left: 45px;
            padding-bottom: 50px;
            border-left: 1px solid $zurich-light-grey;
            h4 {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: $zurich-black;
                margin-bottom: 25px;
            }
            a {
                display: block;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: $zurich-black;
                text-decoration: none;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .menuDesplegadoHeader2-footer {
        display: flex;
        align-items: center;
        background: $zurich-white-two;
        justify-content: space-between;
        padding: 30px;
        border-radius: 0px 0px 20px 20px;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .menuDesplegadoHeader2-footer-a {
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
                margin-bottom: 25px;
            }
            .menuDesplegadoHeader2-footer-a-img {
                margin-right: 10px;
            }
            .menuDesplegadoHeader2-footer-a-title {
                h3 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color:$zurich-dark-blue;
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color:$zurich-dark-grey;
                }
            }
        }
        .menuDesplegadoHeader2-footer-b {
            a {
                display: inline-block;
                background: $zurich-blue;
                border-radius: 10px;
                padding: 8px 16px;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                text-transform: uppercase;
                color:$zurich-white;
                text-decoration: none;
                &:hover {
                    background: $zurich-dark-blue;
                }
            }
        }
    }

    .arrowArribaDesplegable {
        top: -9px;
        left: 290px;
        position: absolute;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    

}

.itemConMenuDesplegable2 {
    position: relative;

    &:hover .menuDesplegadoHeader2 {
        display: flex;
    }
}

