.contenedorPortadaSostenibilidad {
    margin-top: 175px;
    margin-bottom: 42px;
    text-align: center;
    @media (max-width: 768px) {
        margin-top: 100px;
    }
    @media (max-width: 475px) {
    }
    h1 {
        margin-bottom: 15px;
        font-weight: 700;
        font-size: 50px;
        line-height: 68px;
        text-align: center;
        color: $zurich-blue;
        @media (max-width: 475px) {
            font-size: 40px;
        }
    }
}