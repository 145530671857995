.contenedorSantanderSelect {
    padding-top: 100px;
    padding-bottom: 100px;
    .contenedorSantanderSelect-title {
        text-align: center;
        margin-bottom: 50px;
        .contenedorSantanderSelect-parrafo {
            font-weight: 300;
            font-size: 22px;
            line-height: 30px;
            color: #FFFFFF;
            margin-top: 15px;
            b {
                font-weight: 700;
            }
        }
    }
    .contenedorSantanderSelect-cards {
        display: flex;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
        .contenedorSantanderSelect-card-individual {
            background: #707A7C;
            border-radius: 15px;
            padding: 22px 25px 23px;
            width: 30%;
            @media (max-width: 475px) {
                width: 100%;
            }
            .contenedorSantanderSelect-card-individual-texto {
                display: flex;
                align-items: baseline;
                p {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    color: $zurich-light-grey;
                }
            }
        }
    }
}