.card-review-container {
  //width: 450px;
  background: #ffffff;
  box-shadow: 0px 3px 30px RGB(0 0 0 / 4%);
  border-radius: 10px;
  padding: 30px;
  margin: 15px;

  .card-review-image {
    margin-right: 15px;
  }

  .card-review-header {
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 20px;
    .card-review-title {
      h4 {
        font-weight: 700;
        font-size: 13px;
        line-height: 13px;
        text-transform: uppercase;
        color: $zurich-dark-blue;
        margin-bottom: 10px;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: $zurich-dark-grey;
      }
    }
  }
  .card-review-body {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      text-align: justify;
      color: $zurich-dark-blue;
    }
  }
}
