.contenedorNuestroProposito {
    display: flex;
    align-items: center;
    margin-bottom: 150px;
    @media (max-width: 768px) {
        margin-bottom: 80px;
        flex-direction: column;
    }
    .contenedorNuestroProposito-title {
        width: 568px;
        margin-right: 77px;
        @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
            padding: 0 25px;
            margin-bottom: 50px;
        }
        h3 {
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            color: $zurich-blue;
            margin-bottom: 20px;
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: $zurich-black;
            margin-bottom: 50px;
        }

    }
    .contenedorNuestroProposito-imgs {
        padding-left: 70px;
        width: 635px;
        display: flex;
        align-items: center;
        gap: 13px;
        @media (max-width: 768px) {
            width: 100%;
            padding-left: 0;
        }
        div {
            width: 312px;
        }
        .imagenIntermedia {
            margin-bottom: 13px;
        }
    }
}