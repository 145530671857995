.contenedorBannerCv {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px RGB(0 0 0 / 5%);
    border-radius: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 150px;
    padding: 30px;
    @media (max-width: 475px) {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }
    .contenedorBannerCv-title {
        margin-left: 50px;
        margin-right: 70px;
        @media (max-width: 768px) {
            margin-right: 50px;
        }
        h3 {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: $zurich-dark-grey;
            margin-bottom: 10px;
        }
        h2 {
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: $zurich-red;
            margin-bottom: 20px;
        }
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            color: $zurich-dark-blue;
        }
    }
    .contenedorBannerCv-btn {
        a {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.03em;
            color: $zurich-white;
            background: $zurich-blue;
            border-radius: 50px;
            padding: 10px 20px;
            text-decoration: none;
            &:hover {
                background: $zurich-dark-blue;
            }
        }
    }
}