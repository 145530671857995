.contenedorModal {
  .modal-dialog {
    width: 938px;
    max-width: 938px;
    @media (max-width: 768px) {
      width: 95%;
    }
    .modal-content {
      padding: 40px;
      border-radius: 20px;
    }
  }
}
.contenedorBodyModal {
  .contenedorBodyModal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
    div {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
      h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: $zurich-blue;

        &.title_mudanza {
          color: #ff3333;
          font-size: 33px;
        }
      }
    }
    img {
      cursor: pointer;
    }
  }

  .mudanza_subtitile {
    color: #ff3333;
    font-size: 18px;
  }
  .contenedorBodyModal-text {
    margin-bottom: 40px;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #000000;

      &.txt_mudanza {
        color: #6f7072;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .contenedorBodyModal-icons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 45px;

    @media (max-width: 475px) {
      flex-direction: column;
    }

    div {
      width: 190px;
      @media (max-width: 475px) {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
      }
      img {
        margin-bottom: 5px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $zurich-dark-blue;
        width: 131px;
        margin-left: auto;
        @media (max-width: 475px) {
          width: 100%;
          margin-left: 20px;
        }
      }
    }
  }
  .contenedorBodyModal-btn {
    text-align: center;
  }
  .contenedorBodyModal-pdf {
    background: $zurich-light-blue;
    border-radius: 15px;
    padding: 15px 30px;
    margin-bottom: 15px;
    margin-top: 45px;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: $zurich-blue;
      img {
        margin-right: 25px;
        @media (max-width: 475px) {
          margin-right: 5px;
        }
      }
      a {
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
  .contenedorBodyModal-tel {
    background: #f4f6f8;
    border-radius: 15px;
    padding: 30px;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: $zurich-blue;
      a {
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}
.contenedorDinamico-formato-b {
  .textGrey {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: $zurich-dark-grey;
    margin-top: 20px;
    margin-bottom: 70px;
    text-align: center;
  }
}
.contenedorDinamico-formato-c {
  .contenedorBodyModal-btn-c {
    display: flex;
    justify-content: center;
    gap: 30px;
    @media (max-width: 475px) {
      flex-direction: column;
    }
    a {
      background: $zurich-blue;
      border-radius: 50px;
      padding: 10px 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.modalTech {
  p {
    color: $zurich-dark-blue;
  }
}
