.contenedorSegundaOpcionPageItem {
    margin-top: 130px;
    @media (max-width: 768px) {
        margin-top: 90px;
    }
    .contenedorSegundaOpcionPageItem-title {
        h3 {
            font-weight: 400;
            font-size: 30px;
            line-height: 41px;
            text-align: center;
            color: $zurich-blue;
            @media (max-width: 475px) {
                font-size: 28px;
            }
        }
    }
    .contenedorSegundaOpcionPageItem-lists {
        width: 630px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 40px;
        @media (max-width: 475px) {
            width: 100%;
            padding: 0 20px;
        }
        .seccionGrisAsistenciasEspeciales {
            background: #F6F6F6;
            border-radius: 15px;
            padding: 20px 22px;
            margin-bottom: 30px;
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: $zurich-black;
            }
        }
        .contenedorSegundaOpcionPageItem-lists-card {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            div.contenedorSegundaOpcionPageItem-lists-items {
                margin-left: 20px;
                h6 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: $zurich-dark-blue;
                    margin-bottom: 10px;

                }
                .tituloSpanNuevo {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span {
                        background: #99F46E;
                        border-radius: 30px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        text-transform: uppercase;
                        color: #1E9014;
                        padding: 3px 6px;
                        margin-right: 20px;
                    }
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: $zurich-dark-blue;
                }
            }
        }
        
    }
    .contenedorOpcionC {
        display: grid !important;     
        grid-template-columns: 10% 80% 10%;
        .contenedorSegundaOpcionPageItem-lists-items {
            margin-right: 0;
        }
       
    }
    
    
    .tituloAdicionalesContenido {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        color: $zurich-red;
        margin-bottom: 35px;
    }
    .tituloAsistenciasContenido {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        color: $zurich-dark-blue;
        margin-bottom: 35px;
    }
    .subTituloAsistenciasContenido {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-align: left;
        color: $zurich-dark-blue;
        margin-bottom: 20px;
    }
    .contenedorSegundaOpcionPageItem-link {
        text-align: center;
        a {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: $zurich-dark-grey;
            text-decoration: none;
        }
        .primerLinkAzul {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.03em;
            color: $zurich-blue;
            background: transparent;
            border: none;
            margin-right: 30px;
        }
    }
    .tooltip-example {
        position: relative;
        text-align: right;
        .tooltiptext {
            visibility: hidden;
            width: 300px;
            color: #fff;
            text-align: center;
            padding: 10px;
            position: absolute;
            z-index: 1;
            background: $zurich-dark-blue;
            border-radius: 15px;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            top: -50px;
            left: 65px;
        }
        &:hover .tooltiptext {
            visibility: visible;
        }
    }
}
.modalParaOpcionC {
    .modal-dialog {
        max-width:627px;
        .modal-content {
            background: #FFFFFF;
            box-shadow: 0px 10px 30px RGB(0 0 0 / 5%);
            border-radius: 15px;
            margin: 0 auto;
            padding: 40px;
            .ModalContenidoOpcionC {
                .btnVerDetallesModal {
                    margin-top: 25px;
                    a {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: $zurich-dark-blue;
                        text-decoration: none;
                        img {
                            margin-left: 15px;
                        }
                    }
                }
                .ModalContenidoOpcionC-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 29px;
                    h4 {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 25px;
                        color: $zurich-blue;
                    }
                    img {
                        cursor: pointer;
                    }
                }
                .ModalContenidoOpcionC-subtitle {
                    margin-bottom: 34px;
                    display: flex;
                    align-items: center;
                    img {
                        margin-right: 10px;
                    }
                    p {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        color: $zurich-dark-blue;
                    }
                }
                .ModalContenidoOpcionC-body {
                    display: flex;
                    @media (max-width: 475px) {
                        flex-direction: column;
                    }
                    .ModalContenidoOpcionC-list {
                        width: 50%;
                        @media (max-width: 475px) {
                            width: 100%;
                        }
                        p {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 19px;
                            color: $zurich-dark-blue;
                            margin-bottom: 10px;
                            img {
                                margin-right: 10px;
                            }
                        }
                        h6 {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 22px;
                            color: $zurich-dark-blue;
                        }
                        .lineaSeparadoraBeadcroum {
                            margin-bottom: 25px;
                            margin-top: 15px;
                        }
                        
                    }
                    
                }
            }
            
        }
    }
    
}
.headerPersonalizadoModalAsistencias {
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom: 29px;
    h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: $zurich-blue;
    }
    img {
        cursor: pointer;
    }
}
.bodyPersonalizadoModalAsistencias {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    @media (max-width: 475px) {
        flex-direction: column;
    }
    .bodyPersonalizadoModalAsistencias-tel {
        display: flex;
        flex-direction: column;
        a {
            font-weight: 700;
            font-size: 28px;
            line-height: 29px;
            color: $zurich-red;
            text-decoration: none;
        }
        div {
            display: flex;
            flex-direction: column;
            margin-bottom: 35px;
        }
    }
}