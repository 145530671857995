#container-reviews {
  padding-bottom: 135px;
  position: relative;

  @media (max-width: 767px) {
    padding-bottom: 85px;
  }
}
.imagenBackgroundReview {
  position: absolute;
  top: 0;
  z-index: -1;
}
.container-reviews-header {
  margin-bottom: 60px;
  .container-reviews-title {
    margin-bottom: 65px;
    h3 {
      font-size: 16px;
      line-height: 22px;
    }
    h4 {
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      letter-spacing: 0.07em;
      color: $zurich-dark-blue;
      margin-bottom: 0;
    }
  }
  .container-reviews-body {
    display: flex;
  }
  .container-reviews-body-numbers {
    margin-right: 15px;
    p {
      font-weight: 700;
      font-size: 50px;
      line-height: 68px;
      color: $zurich-red;
    }
  }
  .container-reviews-body-stars {
    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      color: $zurich-red;
    }
    span {
      font-weight: 600;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 14px;
      color: $zurich-dark-grey;
    }
  }
}
