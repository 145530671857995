.bg-novedadesZurich {
  background: #f6f6f6;
  padding: 80px 0px;
  margin-bottom: 40px;
}
.novedadesZurich {
  h3 {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: $zurich-blue;
    margin-bottom: 50px;
    text-align: left;
    span {
      font-weight: 700;
    }
  }
  .novedadesZurich-items {
    .novedadesZurich-item {
      width: 315px;
      .novedadesZurich-item-header {
        border-radius: 15px 15px 0px 0px;

        img {
          max-height: 150px;
          width: 100%;
          object-fit: cover;
        }
      }
      .novedadesZurich-item-body {
        background: #ffffff;
        border-radius: 0px 0px 15px 15px;
        padding: 20px;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: $zurich-dark-grey;
          display: inline-block;
          margin-bottom: 10px;
        }
        h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          color: $zurich-blue;
          margin-bottom: 10px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $zurich-dark-blue;
          margin-bottom: 40px;
        }
        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $zurich-black;
          text-decoration: none;
          display: inline-block;
        }
      }
    }
  }
}

.cardItemNovedades-img {
  img {
    max-height: 150px;
    width: 100%;
    object-fit: cover;
  }
}
