.contenedorCardPaises {
    width: 300px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    .contenedorCardPaises-title {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        img {
            margin-right: 10px;
        }
        h6 {
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: $zurich-red;
        }
    }
    .contenedorCardPaises-body {
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $zurich-dark-blue;
        }
    }
}