.contenedorTituloPost {
    margin-top: 60px;
    .contenedorTituloPost-time {
        display: grid;
        grid-template-columns: 33% 33% auto;
        margin-bottom: 30px;
        a {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: $zurich-dark-blue;
            text-decoration: none;
            width: fit-content;
        }
        span {
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            text-align: center;
            color: $zurich-dark-grey;
        }
    }
    .contenedorTituloPost-title {
        display: flex;
        justify-content: center;
        h1 {
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            text-align: center;
            color: $zurich-dark-blue;
            width: 400px;
        }
    }
    .contenedorTituloPost-tags {
        display: flex;
        gap: 15px;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 40px;
        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            text-align: center;
            text-transform: uppercase;
            color: $zurich-dark-blue;
            background: #F4F6F8;
            border-radius: 5px;
            padding: 10px 20px;
        }
    }
}