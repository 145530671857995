body {
  font-family: $font;
  background-color: $zurich-white-two;
}
img {
  max-width: 100%;
}
a,
button {
  transition: all 0.5s ease-out;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.container-fluid {
  padding: 0;
}
.titulo-principal {
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  color: $zurich-blue;
  margin-bottom: 0;
  white-space: pre;

  @media (max-width: 767px) {
    font-size: 36px;
  }
}
.subtitulo-gris {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 0;
  color: $zurich-dark-grey;
  white-space: pre;
  @media (max-width: 767px) {
    font-size: 10px;
  }
}
.extract-azul {
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #003399;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.parrafo-general {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  color: $zurich-black;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.link-default {
  color: $zurich-dark-grey;
}
.link-default:hover {
  color: $zurich-black;
}

.navbar-brand {
  @media (max-width: 767px) {
    img {
      max-width: 200px;
    }
  }
}
.contenedorPersonalizadoContacto {
  padding-left: 150px;
  padding-right: 150px;
  @media (max-width: 768px) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.bg-white-zurich {
  background-color: $zurich-white;
}
body {
  margin-top: 120px;
}
