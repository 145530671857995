.contenedorTituloContacto {
    margin-top: 50px;
    padding-left: 150px;
    padding-right: 150px;
    @media (max-width: 768px) {
        padding-left: 50px;
        padding-right: 50px;
    }
    h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        color: $zurich-blue;
        margin-bottom: 50px;
        br {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}