.contenedorLegales {
  margin-top: 50px;
  margin-bottom: 90px;
  padding-left: 200px;
  padding-right: 200px;

  @media (max-width: 768px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 475px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: $zurich-blue;
    margin-bottom: 50px;
  }
  div {
    margin-bottom: 10px;

    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 35px;
      color: $zurich-black;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 35px;
      color: $zurich-black;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        text-decoration: none;
        font-weight: bold;
        color: $zurich-black;
      }
    }
  }
  .listadoParaDescargarLegales {
    p {
      a {
        font-weight: 400;
      }
    }
  }

  .accordion-item {
    border: 0 !important;
  }
  .accordion-button {
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: $zurich-black;
    border: 0;
    padding: 0;
  }

  .link-pdf-gob {
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: $zurich-black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .accordion-button::after {
    margin-left: 10px !important;
  }
}
