@import "./_variables/fonts";
@import "./_variables/colors";

@import "~bootstrap/scss/bootstrap";
@import "./_variables/buttons";
@import "./globals";



// Slider 

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

//Globals
@import "./_components/Global/bannerContactate";
@import "./_components/Global/btnDenunciarUnSiniestro";
@import "./_components/Global/btnMasInformacion";
@import "./_components/Global/reviews";
@import "./_components/Global/cardReviews";
@import "./_components/Global/header";
@import "./_components/Global/footer";
@import "./_components/Global/tablas";
@import "./_components/Global/breadcrumbs";
@import "./_components/Global/saantanderSelect";


// Nosotros 
@import "./_components/Nosotros/BannerCv";
@import "./_components/Nosotros/Mapa";
@import "./_components/Nosotros/Necesidades";
@import "./_components/Nosotros/NuestroProposito";
@import "./_components/Nosotros/Portada";
@import "./_components/Nosotros/CardPaises";

@import "./_pages/nosotros";

// Sostenibilidad
@import "./_components/Sostenibilidad/NuestrosAportes";
@import "./_components/Sostenibilidad/ParrafoPrincipal";
@import "./_components/Sostenibilidad/Portada";
@import "./_components/Sostenibilidad/TipsSostenibles";
@import "./_components/Sostenibilidad/TrabajamosJuntos";
@import "./_components/Sostenibilidad/CardNuestrosAportes";
@import "./_components/Sostenibilidad/CardTipsSostenible";

@import "./_pages/sostenibilidad.scss";

// Contacto
@import "./_components/Contacto/CardNotWithIcons";
@import "./_components/Contacto/CardWithIcons";
@import "./_components/Contacto/HaceTuDenuncia";
@import "./_components/Contacto/TituloContacto";
@import "./_components/Contacto/SectionSinIconos";
@import "./_components/Contacto/SectionConIconos";

@import "./_pages/contacto.scss";

// Escribenos
@import "./_components/Escribenos/FormEscribenos";
@import "./_components/Escribenos/TituloEscribenos";

@import "./_pages/escribenos.scss";

// Legales
@import "./_pages/legales.scss";

// Novedades
@import "./_components/Novedades/GrillaNovedades";
@import "./_components/Novedades/ItemNovedades";
@import "./_components/Novedades/Portada";

@import "./_pages/novedades.scss";

// Novedades Post
@import "./_components/NovedadesPost/Contenido";
@import "./_components/NovedadesPost/TituloPost";

@import "./_pages/novedadesPost.scss";


// Home
@import "./_components/Home/cardsItems";
@import "./_components/Home/porqueElegirnos";
@import "./_components/Home/NovedadesZurich";
@import "./_components/Home/MenuDesplegableGrande";

@import "./_pages/home";

// PreguntasFrecuentes
@import "./_components/PreguntasFrecuentes/CardsContenido";
@import "./_components/PreguntasFrecuentes/Portada";
@import "./_components/PreguntasFrecuentes/ContenedorPreguntas";
@import "./_components/PreguntasFrecuentes/Copy";
@import "./_components/PreguntasFrecuentes/Item";


@import "./_pages/preguntasFrecuentes";

// TuveUnSiniestro
@import "./_components/TuveUnSiniestro/Portada";
@import "./_components/TuveUnSiniestro/ModalSiniestros";

@import "./_pages/tuveUnSiniestro";

// Tecnologia Protegida
@import "./_components/TecnologiaProtegida/Portada";
@import "./_components/TecnologiaProtegida/CardsItem";
@import "./_components/TecnologiaProtegida/Cards";

    // Tecnologia Protegida - PageItem
    @import "./_components/TecnologiaProtegida/PageItem/Portada";
    @import "./_components/TecnologiaProtegida/PageItem/Contenido";
    @import "./_components/TecnologiaProtegida/PageItem/PageItem";
    @import "./_components/TecnologiaProtegida/PageItem/StoreMobile";
    @import "./_components/TecnologiaProtegida/PageItem/BannerAzul";


@import "./_pages/tecnologiaProtegida";

// Seguro Bici
@import "./_components/seguroDeBici/ContenidoBici";

@import "./_pages/seguroDeBici";

// Hogar y negocio

@import "./_pages/hogarYnegocio";

// Vida

@import "./_pages/vida";







// TODO cambiar colores por variables en todos los scss


