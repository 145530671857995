.contenedorBannerContactateTecnologiaProtegida {
  margin-bottom: 100px;
}
.contenedorReviewsPagesItems {
  margin-top: 100px;
}
.contenedorBreadcrumsInstanciado {
  width: 965px;
  margin: 0 auto;
  margin-top: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.contenedorBreadcrumsCentrado {
  .container {
    .contenedorBreadcrumbs {
      ul {
        justify-content: center;
      }
    }
  }
}
