.CardTipsSostenible {
    width: 314px;
    background: $zurich-white-two;
    .CardTipsSostenible-body {
        padding: 20px;
        text-align: center;
        h6 {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: $zurich-blue;
            margin-bottom: 10px;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $zurich-dark-blue;
        }
    }
}