.containerCardTecnologiaProtegida {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    width: 525px;
    display: flex;
    padding: 45px 10px;
    align-items: center;
    @media (max-width: 475px) {
        width: 100%;
    }
    .containerCardTecnologiaProtegida-img {
        @media (max-width: 475px) {
            width: 30%;
        }
    }
    .containerCardTecnologiaProtegida-body {
        h5 {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: $zurich-dark-grey;
            margin-bottom: 5px;
        }
        h3 {
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            color: $zurich-dark-blue;  
            margin-bottom: 40px;        
        }
        .containerCardTecnologiaProtegida-body-a {

        }
    }
}