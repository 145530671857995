.contenedorPortadaPreguntasFrecuentes {
    background: $zurich-blue;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        color: $zurich-white;
    }
}