.cardItemNovedades {
    width: 314px;
    .cardItemNovedades-body {
        background: $zurich-white;
        border-radius: 0px 0px 15px 15px;
        padding: 20px;
        h5 {
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: $zurich-dark-grey;
            margin-bottom: 10px;
        }
        h2 {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: $zurich-blue;
            margin-bottom: 10px;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $zurich-dark-blue;
            margin-bottom: 40px;
        }
        a {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: $zurich-black;
            text-decoration: none;
        }
    }
}