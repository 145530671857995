.contenedorMapa {
    display: flex;
    align-items: center;
    margin-bottom: 150px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    .contenedorMapa-mapa {
        width: 462px;
        margin-right: 50px;
        @media (max-width: 768px) {
            width: 100%;
            text-align: center;
            margin-right: 0;
        }
    }
    .contenedorMapa-cards {
        width: 708px;
        @media (max-width: 768px) {
            width: 100%;
        }
        h4 {
            font-weight: 400;
            font-size: 32px;
            line-height: 44px;
            color: $zurich-blue;
            margin-bottom: 25px;
            @media (max-width: 768px) {
                margin-top: 50px;
                text-align: center;
            }
        }
        .contenedorTarjetas {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 20px;
            @media (max-width: 768px) {
                justify-content: center;
            }
        }
        .bannerEstamosEntreUnosPocos {
            display: flex;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
            border-radius: 15px;
            padding: 20px 45px;
            padding-bottom: 0 !important;
            @media (max-width: 475px) {
                padding-bottom: 25px !important;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                gap: 20px;
            }
            .bannerEstamosEntreUnosPocos-img {
                margin-right: 20px;
            }
            .bannerEstamosEntreUnosPocos-title {
                h6 {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 38px;
                    letter-spacing: 0.07em;
                    text-transform: uppercase;
                    color: $zurich-red;
                    margin-bottom: 10px;
                }
                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 30px;
                    color: $zurich-dark-blue;
                }
            }
        }
    }
}