.contenedorBadgeGrisVida {
  background: #f6f6f6;
  padding: 20px;
  border: 1px solid $zurich-light-grey;
  border-radius: 15px;
  width: 965px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    width: 680px;
  }
  @media (max-width: 475px) {
    width: auto;
    flex-direction: column;
    margin: 0 20px;
  }
  div {
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 26px;
      color: #000000;
    }
  }
}
.contenedorBreadcrumbsVida {
  width: 965px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 680px;
  }
  @media (max-width: 475px) {
    width: 100%;
  }
}
.contenedorHeaderSegundaTabla {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 965px;
  margin: 0 auto;
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 680px;
    margin: 0 auto;
  }
  @media (max-width: 475px) {
    width: 100%;
  }
  .contenedorPlanSenior {
    gap: 50px;
    background: #fff3f4;
    border: 2px solid #f9c3c3;
    border-radius: 10px;
    padding: 30px 40px;
    @media (max-width: 475px) {
      margin: 0 20px;
    }
    .contenedorPlanSenior-primeraColumna {
      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: $zurich-red;
        margin-bottom: 13px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $zurich-dark-blue;
      }
    }
    .contenedorPlanSenior-segundaColumna {
      display: flex;
      @media (max-width: 475px) {
        flex-direction: column;
        gap: 20px;
      }
      div {
        display: flex;
        gap: 15px;
        padding: 10px 30px;
        background: #ffffff;
        border-radius: 5px;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $zurich-dark-blue;
        }
      }
    }
  }
  .contenedorTituloAsistencias {
    h2 {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-transform: uppercase;
      color: $zurich-dark-blue;
      @media (max-width: 475px) {
        text-align: center;
      }
    }
  }
}
.contenedorItemsGrisVidaPage {
  margin-top: 100px;
}
.cotenedorBeneficiariosVida {
  width: 1259px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  margin-top: 140px;
  @media (max-width: 768px) {
    width: 680px;
    margin: 0 auto;
    flex-direction: column;
    gap: 30px;
    margin-top: 140px;
    padding-bottom: 30px;
  }
  @media (max-width: 475px) {
    width: 100%;
  }
  .cotenedorBeneficiariosVida-1 {
    img {
      margin-left: 60px;
    }
  }
  .cotenedorBeneficiariosVida-2 {
    width: 570px;
    margin-left: 50px;
    margin-right: 70px;
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 475px) {
      width: 100%;
      margin: 0 auto;
      padding: 0 25px;
    }

    h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: $zurich-dark-grey;
      margin-bottom: 10px;
    }
    h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $zurich-red;
      margin-bottom: 20px;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: $zurich-dark-blue;
    }
  }
  .cotenedorBeneficiariosVida-3 {
    a {
      background: $zurich-blue;
      border-radius: 50px;
      color: white;
      padding: 10px 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: #ffffff;
      text-decoration: none;
      img {
        margin-right: 10px;
      }
    }
  }
}

.contenedorAsistenciasVida {
  background: #effbff;
  border-radius: 15px;
  width: 1280px;
  margin: 0 auto;
  padding: 60px 0px;
  margin-top: 60px;
  @media (max-width: 768px) {
    width: 680px;
  }
  @media (max-width: 475px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  h2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: $zurich-blue;
    margin-bottom: 30px;
  }
  .contenedorAsistenciasVida-columnas {
    gap: 10px;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    .contenedorAsistenciasVida-columnas-primeraColumna {
      background: #ffffff;
      border: 2px solid $zurich-light-blue;
      border-radius: 15px;
      width: 458px;
      padding: 20px;
      @media (max-width: 475px) {
        width: 100%;
      }

      .accordion {
        .accordion-item {
          border: none;
          border-bottom: 1px dashed $zurich-light-grey;
          padding-bottom: 15px;
          margin-bottom: 15px;
          .accordion-header {
            margin-bottom: 0px;
            button {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: $zurich-dark-blue;
              padding: 0px;
              img {
                margin-right: 10px;
              }
            }
          }
          .accordion-collapse {
            .accordion-body {
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              color: $zurich-dark-grey;
            }
          }
        }
      }
      .contenedorAsistenciasVida-columnas-primeraColumna-title {
        margin-bottom: 30px;
        h3 {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: $zurich-dark-blue;
          img {
            margin-right: 10px;
          }
        }
      }
      .contenedorAsistenciasVida-columnas-primeraColumna-items {
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $zurich-dark-blue;
          border-bottom: 1px dashed $zurich-light-grey;
          padding-bottom: 15px;
          margin-bottom: 15px;
          img {
            margin-right: 5px;
          }
        }
      }
    }
    .contenedorAsistenciasVida-columnas-segundaColumna {
      background: #ffffff;
      border: 2px solid $zurich-light-blue;
      border-radius: 15px;
      width: 458px;
      padding: 20px;
      @media (max-width: 475px) {
        width: 100%;
      }
      .contenedorAsistenciasVida-columnas-segundaColumna-title {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
        h3 {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: $zurich-dark-blue;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $zurich-dark-grey;
        }
      }
      .contenedorAsistenciasVida-columnas-segundaColumna-items {
        .accordion {
          .accordion-item {
            border: none;
            border-bottom: 1px dashed $zurich-light-grey;
            padding-bottom: 15px;
            margin-bottom: 15px;
            .accordion-header {
              margin-bottom: 0px;
              button {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $zurich-dark-blue;
                padding: 0px;
                img {
                  margin-right: 10px;
                }
              }
            }
            .accordion-collapse {
              .accordion-body {
                font-weight: 400;
                font-size: 12px;
                line-height: 24px;
                color: $zurich-dark-grey;
              }
            }
          }
        }
      }
    }
    .contenedorAsistenciasVida-buttons {
      background: $zurich-white-two;
      border: 1px solid $zurich-light-grey;
      border-radius: 15px;
      padding: 15px 34px;
      width: 370px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      @media (max-width: 475px) {
        width: 100%;
      }
      div {
        display: flex;
        align-items: center;
        .iconMedio {
          margin: 0 10px;
        }
        p {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: $zurich-dark-blue;
          text-align: left;
          margin-right: 25px;
          @media (max-width: 475px) {
            font-size: 11px;
          }
        }
      }
    }
  }
}
.contenedorSegundaTablaVida {
  .container {
    .cotenedorTablaGlobal {
      margin-top: 18px;
    }
  }
}
.cotenedorHeaderTablaSola {
  width: 965px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 625px 160px 160px;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 130px;
  @media (max-width: 768px) {
    width: 680px;
    grid-template-columns: 500px 80px 80px;
  }
  @media (max-width: 475px) {
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    width: 100%;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cotenedorHeaderTablaSola-titulo {
    @media (max-width: 475px) {
      width: 100%;
    }
    h5 {
      font-weight: 400;
      font-size: 30px;
      line-height: 41px;
      color: $zurich-blue;
    }
  }
  .cotenedorHeaderTablaSola-primercuadro {
    text-align: center;
    background: $zurich-light-red;
    border-radius: 10px 10px 0px 0px;
    @media (max-width: 475px) {
      width: 45%;
    }
  }
  .cotenedorHeaderTablaSola-segundocuadro {
    text-align: center;
    background: $zurich-light-red;
    border-radius: 10px 10px 0px 0px;
    @media (max-width: 475px) {
      width: 45%;
    }
  }
}
.red-zs {
  color: $zurich-red !important;
}

.contenedorAsistenciasVida-columnas-dropdown {
  width: 510 * 2px;
  margin: 0 auto;

  @media (max-width: 475px) {
    width: 100%;
  }

  .accordion-item {
    border: 2px solid #c7f0ff;
    border-radius: 10px;
  }

  .accordion-header {
    margin: 0;
  }

  .accordion-body {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .h1-accordion button {
    font-size: 22px;
    font-weight: bold;
    color: #000 !important;
  }

  .linkDescargaIcon {
    background-image: url("../../assets/images/icondescarga.png");
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #003399 !important;
  }

  .listadescargas h6 {
    padding-top: 15px;
  }

  .listadescargas li {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .listadescargas li h6 {
    font-size: 18px;
    font-weight: bold;
    color: #003399 !important;
  }
}
