.contenedorCopyPreguntasFrecuentes {
    background: #F6F6F6;
    border-radius: 15px;
    padding: 20px 22px;
    margin-bottom: 100px;
    p {
        font-weight: 400;
        font-size: 13px;
        line-height: 26px;
        color: $zurich-black;
        margin-bottom: 10px;
    }
}