.contenedorCardNuestrosAportes {
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
  .contenedorCardNuestrosAportes-body {
    border-radius: 0px 0px 15px 15px;
    padding: 20px 20px 42px;
    h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $zurich-blue;
      margin-bottom: 10px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $zurich-dark-blue;
      margin-bottom: 40px;
    }
    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-decoration: none;
      color: $zurich-black;
    }
  }
}

.contenedorCardNuestrosAportes-title {
  img {
    max-height: 100px;
    width: 100%;
    object-fit: cover;
  }
}
