.btn-denunciar-un-siniestro {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding: 18px 30px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 70px;
    text-decoration: none;
    background-color: $zurich-red;
    color: $zurich-white;
    position: fixed;
    bottom: 35px;
    right: 35px;
    &:hover {
        color: $zurich-white;
        background-color: $zurich-dark-red;
    }
}