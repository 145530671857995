#container-porqueElegirnos {
  margin-top: 125px;
  margin-bottom: 125px;

  @media (max-width: 767px) {
    margin-top: 65px;
    margin-bottom: 65px;
  }
}
.porqueElegirnos {
  .porqueElegirnos-text {
    width: 527px;

    @media (max-width: 767px) {
      width: 100%;
    }

    h3 {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: $zurich-red;
      margin-bottom: 25px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: $zurich-black;
    }
    .porqueElegirnos-listCards {
      margin-top: 30px;
      margin-bottom: 60px;
      .porqueElegirnos-card {
        background: #ffffff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        padding: 19px 0 19px 25px;
        .porqueElegirnos-img {
          text-align: center;
          margin-right: 25px;
        }
        .porqueElegirnos-title {
          h4 {
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            color: $zurich-dark-blue;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            color: $zurich-dark-blue;
          }
        }
      }
    }
  }
  .porqueElegirnos-imagen {
    margin-left: 35px;
  }
}
