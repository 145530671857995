.banner-contactate {
  .banner-contactate-card {
    background: white;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
    margin-bottom: 49px;
    border-radius: 20px;
    @media (max-width: 768px) {
      flex-direction: column !important;
      align-items: center;
    }
    .banner-contactate-card-1 {
      padding: 40px 40px 40px 45px;
      background-color: $zurich-red;

      align-items: flex-start;
      border-radius: 20px 0px 0px 20px;

      @media (max-width: 768px) {
        border-radius: 20px 20px 20px 20px;
        width: 100%;
        align-content: center !important;
        justify-content: center !important;
      }

      p {
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        color: $zurich-white;
      }
      img {
        margin-right: 10px;
      }
    }
    .banner-contactate-card-2 {
      padding: 26px 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .banner-contactate-card-2-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $zurich-dark-grey;
      }

      .banner-contactate-card-2-title-blue {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.07em;
        color: $zurich-blue;
      }
      .banner-contactate-card-2-number {
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: 0.07em;
        color: $zurich-dark-blue;
      }
      .banner-contactate-card-2-hours {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $zurich-dark-blue;
      }
    }
    .banner-contactate-card-3 {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        margin-bottom: 50px !important;
      }
      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        text-decoration: none;
        background-color: $zurich-red;
        color: $zurich-white;
        padding: 16px 20px;
        border-radius: 50px;
        text-align: center;
        &:hover {
          background-color: $zurich-dark-red;
        }
      }
    }
  }
}

.banner-contactate-footer-text {
  p {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: $zurich-dark-grey;
  }
}

.text-legales {
  p {
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $zurich-dark-grey;
  }
}

.link-simple {
  color: $zurich-dark-grey;
  text-decoration: none;
}
