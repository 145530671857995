// COLORS
$zurich-red: #ec0000;
$zurich-dark-red: #ae0000;
$zurich-light-red: #fbe8e9;
$zurich-blue: #003399;
$zurich-dark-blue: #00263e;
$zurich-light-blue: #C7F0FF;
$zurich-black: #000;
$zurich-white: #fff;
$zurich-light-grey: #eaeaea;
$zurich-dark-grey: #6f7072;
$zurich-white-two: #FCFDFD;


/* CUSTOM BOOTSTRAP */
$theme-colors: (
  "info": $zurich-dark-grey,
  "danger": $zurich-red,
  "primary": $zurich-blue,
);
