.contenedorContenedorPreguntas {
  section {
    /*
    display: flex;
    flex-wrap: wrap;
    gap: 38px;
    */
    column-count: 2;
    column-gap: 35px;
    margin-bottom: 239px;
    @media (max-width: 475px) {
      /*flex-wrap: nowrap;
      flex-direction: column;
      margin-bottom: 50px;*/
      column-count: 1;
    }
    .itemsCardsContendo {
      margin-bottom: 30px;
      width: 100%;
      break-inside: avoid;
      @media (max-width: 475px) {
        width: 100%;
       
      }
      .itemsCardsContendo-title {
        margin-bottom: 20px;
        h4 {
          font-weight: 700;
          font-size: 22px;
          line-height: 30px;
          color: $zurich-dark-blue;
        }
      }
    }
  }
}
