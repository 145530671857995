.contenedorBannerServiciosBici {
    h6 {
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        color: $zurich-blue;
    }
    .cards-home {
        padding-top: 50px;
    }
    
}
.contenedorReviewsSeguroBici {
    margin-top: 150px;
}