.espacioGrisSelect {
  margin-top: 150px;
}
.contenedorAdicionalMascotas {
  margin-top: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 0px;
  }
  .contenedorAdicionalMascotas-primeraColumna {
    width: 55%;
    @media (max-width: 768px) {
      width: 90%;
      margin: 0 auto;
    }
  }
  .contenedorAdicionalMascotas-segundaColumna {
    width: 35%;
    @media (max-width: 768px) {
      width: 90%;
      margin: 0 auto;
    }
    .contenedorAdicionalMascotas-segundaColumna-title {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: $zurich-red;
      margin-bottom: 25px;
    }
    .contenedorAdicionalMascotas-segundaColumna-parrafo {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 25px;
    }
    .contenedorAdicionalMascotas-segundaColumna-list {
      .contenedorAdicionalMascotas-segundaColumna-list-card {
        display: flex;
        margin-bottom: 20px;
        .contenedorAdicionalMascotas-segundaColumna-list-card-b {
          margin-left: 15px;
          h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $zurich-dark-blue;
            margin-bottom: 10px;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: $zurich-dark-blue;
          }
        }
      }
    }
  }
}
.contenedorAsistenciaMascotas {
  background: #effbff;
  padding: 35px;
  display: flex;
  margin-bottom: 80px;
  margin-top: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .contenedorAsistenciaMascotas-primeraColumna {
    width: 37%;
    margin-right: 40px;
    @media (max-width: 768px) {
      width: 100%;
    }
    h5 {
      font-weight: 400;
      font-size: 30px;
      line-height: 41px;
      color: $zurich-blue;
      margin-bottom: 20px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #000000;
    }
  }
  .contenedorAsistenciaMascotas-segundaColumna {
    width: 63%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
      margin-top: 50px;
    }
    .contenedorAsistenciaMascotas-segundaColumna-card {
      background: #ffffff;
      border-radius: 10px;
      width: 169px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 20px;
      align-items: center;
      @media (max-width: 475px) {
        width: 45%;
      }
      img {
        width: 45px;
      }
      h6 {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: $zurich-dark-blue;
        height: 75px;
        @media (max-width: 475px) {
          height: auto;
        }
      }
      h6.title-app-blue {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: $zurich-blue;
      }
    }
  }
}
.contenedorPersonalizadoAsistenciaMascotas {
  width: 1281px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.contenedorBagdeAsistencia {
  width: 630px;
  margin: 0 auto;
  background: #f6f6f6;
  border-radius: 15px;
  padding: 20px 0px;
  @media (max-width: 768px) {
    width: 100%;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    text-align: center;
    a {
      font-weight: bold;
      color: #000000;
      text-decoration: none;
    }
  }
}
