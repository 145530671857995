
.cardNotWitchIcons-card {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px 84px 20px 20px;
    margin-bottom: 40px;
    h6 {
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        color: $zurich-dark-blue;
        margin-bottom: 10px;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $zurich-dark-blue;
        margin-bottom: 20px;
    }
    a {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: $zurich-dark-blue;
        text-decoration: none;
        img {
            margin-left: 8px;
        }
    }
}