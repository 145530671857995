.contenedorTrabajemosJuntos {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding:39px 25px;
    margin-bottom: 150px;
    @media (max-width: 475px) {
        padding: 55px;
        margin-bottom: 100px;
        margin-top: 100px;
    }
    h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: $zurich-dark-blue;
    }
    .contenedorTrabajemosJuntos-logos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 44px;
        @media (max-width: 768px) {
            flex-direction: column;
            img {
                margin-bottom: 25px;
            }
        }
    }
}