.contenedorAportesRealizados {
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: $zurich-blue;
    margin-bottom: 50px;
    @media (max-width: 475px) {
      font-size: 25px;
    }
  }
  .contenedorAportesRealizados-cards {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 768px) {
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
    }
  }
}
