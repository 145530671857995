.contenedorBreadcrumbs {
    
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        @media (max-width: 475px) {
            flex-direction: column;
            gap: 20px;
            margin-top: 25px;
        }
        .contenedorBreadcrumbs-blue {
            button {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.03em;
                color: $zurich-blue;
                border: none;
                background-color: transparent;
                margin-right: 35px;
                background: #FFFFFF;
                border-radius: 50px;
                padding: 10px 20px;
                @media (max-width: 475px) {
                    margin-right: 0;
                }
                img {
                    margin-left: 35px;
                }
            }
        }
        .contenedorBreadcrumbs-grey {
            a {
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: $zurich-dark-grey;
                border: none;
                background-color: transparent;
                text-decoration: none;
                img {
                    margin-left: 8px;
                }
            }
        }
    }
}