.ContenedorBannerAzul {
    background: #F4F6F8;
    border-radius: 15px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 105px;
    p {
        margin-left: 20px;
        font-weight: 400;
        font-size: 15px;
        line-height: 26px;
        color: $zurich-blue;
        a {
            text-decoration: none;
            font-weight: bold;
        }
    }
}