.contenedorPersonalizadoEscribenos {
    padding: 0 235px;
    @media (max-width: 768px) {
        padding: 0;
        text-align: center;
        margin-bottom: 50px;
    }
    .contenedorTituloEscribenos {
        margin-top: 50px;
        h1 {
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: $zurich-blue;
            margin-bottom: 15px;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $zurich-dark-blue;
            margin-bottom: 18px;
        }
    }
}
