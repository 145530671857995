.contenedorCardsContenidoPreguntasFrecuentes {
  .contenedorCardsContenidoPreguntasFrecuentes-body {
    display: flex;
    text-decoration: none;
    align-items: center;
    background: #ffffff;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
    justify-content: space-between;

    .contenedorCardsContenidoPreguntasFrecuentes-description {
      h5 {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: $zurich-dark-blue;
        margin-bottom: 5px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: $zurich-dark-grey;
      }
    }
  }
}
