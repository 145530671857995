.ContenedorPortadaPageItem {
  height: 755px;
  padding-top: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    height: 425px;
    padding-top: 80px;
  }
  @media (max-width: 475px) {
    height: 300px;
    background-position: top;
  }
  h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $zurich-dark-grey;
  }
  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
    text-align: center;
    color: $zurich-blue;
    @media (max-width: 375px) {
      font-size: 40px;
      line-height: 45px;
    }
  }
}
