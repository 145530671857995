.contenedorHeader {
    margin-top: 175px;
    margin-bottom: 29px;
    text-align: center;
    @media (max-width: 475px) {
        margin-top: 80px;
        margin-bottom: 150px;
    }
    h1 {
        font-weight: 700;
        font-size: 50px;
        line-height: 68px;
        text-align: center;
        color:$zurich-blue;
    }
}