.contenedorCardConIconos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}
.cardWithIconsContacto {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px RGB(0 0 0 / 5%);
    border-radius: 10px;
    width: 276px;
    padding: 20px;
    img {
        margin-bottom: 15px;
    }
    h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: $zurich-red;
        margin-bottom: 10px;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $zurich-dark-blue;
        margin-bottom: 20px;
    }
    a {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: $zurich-dark-blue;
        text-decoration: none;
        img {
            margin-bottom: 0;
            margin-left: 8px;
        }
    }
}