.titulos-cards {
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: $zurich-dark-blue;
  white-space: pre;
}
.cards-items {
  padding: 40px;
  text-align: center;
  box-shadow: 0px 10px 30px RGB(0 0 0 / 5%);
  border-radius: 20px;
  p {
    margin-bottom: 5px;
  }
  h3 {
    margin-bottom: 40px;
    min-height: 82px;
  }
}
img.icon-card-items {
  margin-bottom: 25px;
}
