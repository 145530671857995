.contenedorPortadaTuveUnSiniestro {
    margin-top: 190px;
    margin-bottom: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    @media (max-width: 475px) {
        margin-top: 125px;
        margin-bottom: 125px;
    }
    .contenedorPortadaTuveUnSiniestro-columnas {
        width: 50%;

        @media (max-width: 768px) {
            width: 100%;
            img {
                margin-bottom: 25px;
            }
        }
    }
    .contenedorPortadaTuveUnSiniestro-title {
        margin-bottom: 50px;
        text-align: center;
        h2 {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: $zurich-dark-grey;
            @media (max-width: 475px) {
                font-size: 11px;
            }
        }
        h1 {
            font-weight: 700;
            font-size: 38px;
            line-height: 52px;
            text-align: center;
            color: $zurich-blue;
            margin-bottom: 15px;
            @media (max-width: 475px) {
                font-size: 32px;
            }
        }
        h3 {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $zurich-dark-blue;
            @media (max-width: 475px) {
                font-size: 14px;
            }
        }
    }
    .contenedorPortadaTuveUnSiniestro-dropdown {
        text-align: center;
        .dropdown {
            #dropdown-selecciona-tu-seguro {
                background: #FFFFFF;
                border: 1px solid $zurich-light-grey;
                box-shadow: 0px 10px 30px RGB(0 0 0 / 5%);
                border-radius: 40px;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: $zurich-red;
                padding: 15px 30px;
                &::after {
                    margin-left: 165px;
                    border: none;
                    content: url('../../../assets/images/TuveUnSiniestro/IconDropdown.svg');
                }
            }
            .dropdown-menu {
                background: #FFFFFF;
                box-shadow: 0px 10px 30px RGB(0 0 0 / 5%);
                border-radius: 20px;
                padding: 30px 20px;
                width: 396px;
                height: 328px;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    background: white;   
                }
                &::-webkit-scrollbar-thumb {
                    background: #C4C4C4;
                    border-radius: 30px;   
                }
                a {
                    padding: 0px;
                    margin-bottom: 20px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: $zurich-dark-blue;
                }
            }
            
        }
    }
}