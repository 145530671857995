.btnMasInformacion {
    background-color: $zurich-blue;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color:$zurich-white;
    text-decoration: none;
    img {
        margin-left: 35px;
    }
    &:hover {
        background-color: $zurich-dark-blue;
        color:$zurich-white;
    }
    @media (max-width: 375px) {
        font-size: 13px;
    }
}