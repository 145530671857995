.contenedorStoreMobileProtegida {
  background: #ffffff;
  padding: 0px 57px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 41px;
  margin-top: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 25px;
  }
  .contenedorStoreMobileProtegida-title {
    margin-left: 50px;
    margin-right: 0px;
    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px;
    }
    h4 {
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $zurich-red;
      @media (max-width: 475px) {
        font-size: 21px;
      }
    }
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: $zurich-dark-blue;
      @media (max-width: 475px) {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
  .contenedorStoreMobileProtegida-mobile {
    display: flex;
    gap: 30px;
    @media (max-width: 375px) {
      flex-direction: column;
    }
  }
}
