.contenedorNecesidades {
    margin-bottom: 150px;
    h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color:$zurich-dark-blue;
        margin-bottom: 31px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 38px;
        text-align: center;
        color:$zurich-dark-blue;
    }
}