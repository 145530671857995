.contenedorParrafoPrincipalSustentabilidad {
  padding: 0 200px;
  margin-bottom: 130px;
  @media (max-width: 768px) {
    padding: 0 100px;
    margin-bottom: 100px;
  }
  @media (max-width: 475px) {
    padding: 0 50px;
  }

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: $zurich-blue;
    margin-bottom: 20px;
    @media (max-width: 475px) {
      font-size: 25px;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
    color: $zurich-dark-blue;
  }
}
