.contenedorTipsSostenibles {
    margin-bottom: 130px;
    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: $zurich-dark-blue;
        margin-bottom: 50px;
    }
    .contenedorTipsSostenibles-cards {
        display: flex;
        gap: 30px;
        justify-content: center;
        @media (max-width: 475px) {
            flex-wrap: wrap;
        }
    }
}