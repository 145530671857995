.fondoPortadaDestacada {
    padding: 129px 100px;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 50px;
    margin-top: 50px;
    @media (max-width: 475px) {
        padding: 129px 50px;
    }
    
}
.contenedorPortadaNovedades {
    background: rgba(0, 51, 153, 0.8);
    mix-blend-mode: normal;
    border-radius: 10px;
    width: 481px;
    padding: 20px;
    @media (max-width: 475px) {
        width: 100%;
    }
    h1 {
        font-weight: 700;
        font-size: 26px;
        line-height: 35px;
        color: $zurich-white;
        margin-bottom: 12px;
    }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $zurich-white;
        margin-bottom: 12px;
    }
    a {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: $zurich-white;
        text-decoration: none;
    }
    
}