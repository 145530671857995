.cotenedorTablaGlobal {
    width: 965px;
    margin: 0 auto;
    margin-top: 150px;
    @media (max-width: 768px) {
        width: 680px;
    }
    @media (max-width: 475px) {
        width: 100%;
    }
    .cotenedorTablaGlobal-header {
        display: grid;
        grid-template-columns: 625px 160px 160px;
        gap: 10px;
        margin-bottom: 10px;
        @media (max-width: 768px) {
            grid-template-columns: 500px 80px 80px;
        }
        @media (max-width: 475px) {
            grid-template-columns: 1fr 1fr 1fr;
            display: flex;
            flex-flow: wrap;
            gap: 20px;
            width: 100%;
            justify-content: center;
            padding-left: 20px;
            padding-right: 20px;
        }
        .cotenedorTablaGlobal-header-titulo {
            display: flex;
            align-items: center;
            h5 {
                font-weight: 400;
                font-size: 28px;
                line-height: 38px;
                color: $zurich-blue;
                width: 400px;
                @media (max-width: 475px) {
                    width: 100%;
                }
            }
        }
        .cotenedorTablaGlobal-header-primercuadro {
            background: $zurich-light-red;
            border-radius: 10px 10px 0px 0px;
            padding: 7px;
            @media (max-width: 475px) {
                width: 45%;
            }
            h5 {
                font-weight: 700;
                font-size: 22px;
                line-height: 30px;
                text-align: center;
                color: $zurich-red;
            }
        }
        .cotenedorTablaGlobal-header-segundocuadro {
            background: $zurich-light-red;
            border-radius: 10px 10px 0px 0px;
            padding: 7px;
            @media (max-width: 475px) {
                width: 45%;
            }
            h5 {
                font-weight: 700;
                font-size: 22px;
                line-height: 30px;
                text-align: center;
                color: $zurich-red;
            }
        }
    }
    .cotenedorTablaGlobal-body {
        .cotenedorTablaGlobal-body-items {
            display: grid;
            grid-template-columns: 625px 160px 160px;
            gap: 10px;
            margin-bottom: 10px;
            @media (max-width: 768px) {
                grid-template-columns: 500px 80px 80px
            }
            @media (max-width: 475px) {
                width: 100%;
                grid-template-columns: 1fr 1fr 1fr;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            .cotenedorTablaGlobal-body-1 {
                background: white;
                display: grid;
                grid-template-columns: 10% 80% 10%;
                gap: 10px;
                align-items: center;
                padding: 10px 20px;

                h5 {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: $zurich-dark-blue;
                }
            }
            .cotenedorTablaGlobal-body-2 {
                display: flex;
                align-items: center;
                justify-content: center;
                background: white;
                padding: 10px 0;
                @media (max-width: 475px) {
                    width: 48%
                }
            }
            .cotenedorTablaGlobal-body-3 {
                display: flex;
                align-items: center;
                justify-content: center;
                background: white;
                padding: 10px 0;
                @media (max-width: 475px) {
                    width: 48%
                }
            }
        }
    }
}