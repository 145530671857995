.portada-home {
  padding-top: 150px;
  padding-bottom: 375px;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 40px;
  }
}
.portada-home-imagen {
  z-index: -1;
  position: absolute;
  top: 320px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.section-seguro-hogar {
  position: relative;
}
.seguro-hogar-home {
  margin-top: 190px;
  padding-bottom: 540px;
}
.seguro-hogar-home {
  h2 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 40px;
  }
}
.portada-seguro-hogar-imagen {
  z-index: -1;
  position: absolute;
  top: -250px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.cards-home {
  margin-bottom: 80px;
  padding-top: 200px;

  @media (max-width: 767px) {
    margin-bottom: 100px;
    padding-top: 70px;
  }
}

.section-portada-top {
  &.welcome {
    padding-top: 150px;

    background-image: url("../../assets/images/portada-welcome.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    min-height: 800px;

    @media (max-width: 767px) {
      padding-top: 100px;
      min-height: 600px;
    }

    h1 {
      color: $zurich-red;
    }
  }

  &.first {
    padding-top: 150px;

    background-image: url("../../assets/images/portada-home.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    min-height: 800px;

    @media (max-width: 767px) {
      padding-top: 100px;
      min-height: 600px;
    }
  }

  &.second {
    padding-top: 150px;

    background-image: url("../../assets/images/portada-seguro-hogar.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    min-height: 800px;

    @media (max-width: 767px) {
      padding-top: 20px;
      min-height: 700px;
    }
  }
}
